import React from 'react'

import { PageLayout, PageBreadcrumb } from '../../../components'
import { t } from '../../../lib/locale'

const ContentVacaturesJSDeveloperTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderNL = (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="mb-4">FULL-STACK JS DEVELOPER - GEEL</h1>
          <h4>Wie we zijn? </h4>
          <p>Up North is een winkel en webshop van Belgische bodem. Met moois dat uit Scandinavië - of andere Europese landen - komt overwaaien willen we klanten voorzien van een tof interieur en de leukste cadeaus. Denk aan unieke decoratie- en kidsitems van duurzame merken zoals HAY, Muuto, ferm LIVING, Liewood … Advies op maat, een glimlach en een mooie strik rond de pakjes krijgt iedereen er gratis bij. ;)</p>
          <p>Heb je zin om deel uit te maken van een jong & dynamisch team en werk je graag in het hartje van de Kempen? Lees dan snel verder! </p>
          <h4>Jouw verantwoordelijkheden? </h4>
          <p>We zoeken iemand die mee helpt aan de ontwikkeling van onze IT systemen, waarop de webshop en onze winkel momenteel draait. Afhankelijk van jouw kennis en interesses, ontwikkel je mee op de frontend of backend, of beide, waarbij we in een agile methode continu grote en kleine features uitrollen naar onze eindgebruikers. Je wordt betrokken in architecturale discussies, alsook in discussies omtrent hosting, testing, high availability and the list goes on.</p>
          <h4>Jouw profiel?</h4>
          <ul>
            <li>Je spreekt vloeiend Nederlands of Engels.</li>
            <li>Je hebt reeds ervaring in een soortgelijke functie, maar dit is geen must.</li>
            <li>Je bent leergierig en gemotiveerd om nieuwe en opkomende technologiën en architectuur best practices onder de knie te krijgen.</li>
            <li>Je bent eerlijk, authentiek en behulpzaam. Geen vraag is je teveel en je geeft advies zoals je zelf geadviseerd wil worden.</li>
            <li>Je bent sociaal en communicatief.</li>
          </ul>
          <h4>Ons aanbod? </h4>
          <p>Je komt terecht in een jong, dynamisch team waar oprecht geluisterd wordt naar jouw inbreng en feedback. Er zijn groeimogelijkheden, en je krijgt een aantrekkelijke verloning.</p>
          <p>Wij staan ook open voor stages, vakantiewerk, tijdelijke of vaste werknemers, zowel full-time als part-time.</p>
          <h4>Hoe solliciteer je? </h4>
          <p>Stuur snel jouw CV en motivatie naar <a href="mailto:stefan.vunckx@shopupnorth.com">stefan.vunckx@shopupnorth.com</a>!</p>
        </div>
      </div>
    </>
  )

  const renderEN = (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="mb-4">FULL-STACK JS DEVELOPER - GEEL</h1>
          <h4>Who are we? </h4>
          <p>Up North is a Belgian store and webshop. With beautiful things from Scandinavia - or any other European country - we want to provide customers with a great interior and the best gifts. Think of unique decoration and kids items from sustainable brands such as HAY, Muuto, ferm LIVING, Liewood ... Customized advice, a smile and a beautiful wrapping around the packages are included for free. ;)</p>
          <p>Would you like to be part of a young & dynamic team and would you like to work in the heart of the Kempen? Then read on quickly! </p>
          <h4>Your responsibilities? </h4>
          <p>We are looking for someone to help build and maintain our IT systems for our webshop and shop in Geel. Depending on your expertise and interests, you will develop on the frontend or the backend, or both. We do this in an agile way, where we continuously deliver big and small features to our end-users. You will have a say in architectural discussions, as well as dicussions around hosting, testing, high availability and the list goes on.</p>
          <h4>Your profile?</h4>
          <ul>
            <li>You speak Dutch or English fluently.</li>
            <li>You have experience in a similar position.</li>
            <li>Diploma in sales or interior architecture is a plus.</li>
            <li>You are eager to learn and grow, and you are motivated to get your hands on new and emerging technologies and architecture patterns.</li>
            <li>You are honest, authentic and helpful. No question is too much for you and you give advice as you would like to be advised yourself. </li>
            <li>You are social and communicative.</li>
          </ul>
          <h4>Our offer?</h4>
          <p>You will join a young, dynamic team where you sincerely listen to your input and feedback. There are opportunities for growth, you will receive an attractive salary.</p>
          <p>We are open for mulitple types of contract, either part-time or full-time, so if you are interested do not hesitate to apply!</p>
          <h4>How do you apply?</h4>
          <p>Send your CV and motivation quickly to <a href="mailto:stefan.vunckx@shopupnorth.com">stefan.vunckx@shopupnorth.com</a>!</p>
        </div>
      </div>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentVacaturesJSDeveloperTemplate
